<template>
  <div class="house-detail">
    <app-detail :opt="opt" ref="detail"></app-detail>
  </div>
</template>
<script>
var validatePrice = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入价格"));
  } else {
    if (value < 0) {
      callback(new Error("最低输入0"));
    }
    callback();
  }
};

var validateQuantity = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入数量"));
  } else {
    if (value < 1) {
      callback(new Error("最低输入1"));
    }
    callback();
  }
};
export default {
  data() {
    let _this = this;
    return {
      ite: 1,
      item: 1,
      typeList:[],
      tableData: [
        {
          name: "",
          date: "",
          mount: "",
          price: ""
        }
      ],

      arr: [],
      opt: {
        title: "房型详情",
        form: [
          {
            label: "房型名称",
            key: "typeId",
            type: "select",
            isCol50: true,
            isWidth67: true,
            opt: {
              list: [
                {
                  value: 1,
                  label: "两房一厅"
                },
                {
                  value: 2,
                  label: "三房一厅"
                },
                {
                  value: 3,
                  label: "四房两厅"
                }
              ]
            },
            rules: [{ required: true }]
          },
          {
            label: "所属房源",
            key: "apartmentId",
            type: "select-filterables",
            isCol50: true,
            isWidth67: true,
            opt: {
              list: [],
              multiple: false,
              isLoading: false,
              on() {}
            },
            rules: [{ required: true }]
          },
          {
            label: "面积（㎡）",
            key: "area",
            type: "number",
            isCol50: true,
            isWidth67: true,
            rules: [{ required: true }]
          },
          // {
          //   label: "网费",
          //   key: "netFee",
          //   type: "number",
          //   isCol50: true
          // },
          {
            label: "电梯",
            key: "lift",
            type: "select",
            opt: {
              list: [
                {
                  value: 1,
                  label: "有"
                },
                {
                  value: 2,
                  label: "无"
                }
              ]
            },
            isCol50: true,
            isWidth67: true,
            rules: [{ required: true }]
          },
          {
            label: "配套",
            key: "facilities",
            type: "checkbox",
            opt: {
              list: [
                {
                  id: "1",
                  name: "电梯"
                }
              ]
            }
          },
          {
            label: "物品清单",
            key: "roomItemsList",
            type: "table",
            // rules: {
            //   itemName: [
            //     { required: true, message: "请输入名称", trigger: "blur" }
            //   ],
            //   itemQuantity: [{ validator: validateQuantity, trigger: "blur" }],
            //   itemPrice: [{ validator: validatePrice, trigger: "blur" }]
            // },
            opt: {
              columns: [
                {
                  label: "名称",
                  key: "itemName"
                },
                { label: "品牌", key: "itemBrand" },
                {
                  type: "number-pro",
                  label: "数量",
                  key: "itemQuantity",
                  max: 20,
                  min: 0
                },
                {
                  type: "number-pro",
                  label: "价格（元）",
                  key: "itemPrice",
                  max: 99999999999,
                  min: 0
                }
              ],
              default: (() => {
                let arr = [],
                  tempName = ["床", "衣柜", "空调", "热水器", "油烟机", "沙发"];
                for (var i = 0; i < tempName.length; i++) {
                  arr.push({
                    itemBrand: "无",
                    itemName: tempName[i],
                    itemPrice: 0,
                    itemQuantity: 1
                  });
                }
                return arr;
              })()
            }
          },
          {
            label: "物品清单说明",
            key: "itemDescription",
            type: "textarea"
          },
          {
            label: "上传照片",
            key: "imgs",
            type: "upload-img-form",
            rules: [{ required: true }],
            opt: {
              alt:
                "支持上传1-6张图片，支持png、jpg格式 *第一张为主照片，供手机端首页展示",
              url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
              urlKey: "picPath",
              upload(data) {
                console.log(data);
                return true;
              },
              remove(file, fileList) {
                console.log(file, fileList);
                return true;
              }
            }
          },
          {
            label: "房型简介",
            key: "description",
            type: "textarea"
          }
        ],
        buttons: [
          {
            name: "提交",
            show: ["add", "edit"],

            cb(form) {
              if (form.roomItemsList.length != 0) {
                form.roomItemsList.forEach(item => {
                  if (!item.itemBrand) {
                    item.itemName = "无";
                    item.itemBrand = "无";
                  }
                });
              }

              let dto = {
                apartmentId: form.apartmentId.toString(),
                landlordId: _this.cache.getLS("userInfo")["userId"],
                area: form.area.toString(),
                typeId: form.typeId.toString(),
                leasingMode: "1",
                lift: form.lift.toString(),
                description: form.description,
                facilities: form.facilities.join(","),
                roomItemsList: form.roomItemsList,
                itemDescription: form.itemDescription
              };
              // 创建图片提交字段 pic1 pic2 pic3 ...
              form.imgs.forEach((data, index) => {
                dto["pic" + (index + 1)] = data.url;
              });
              _this.typeList.forEach(item =>{
                if(form.typeId == item.value){
                  dto.typeName = item.label
                }
              })

              let url = "landlord-service/roomType/createRoomType";
              let message = "添加房型成功";
              if (form.id) {
                url = "landlord-service/roomType/updateRoomType";
                dto.id = form.id;
                message = "编辑房型成功";
              }
              _this.post(url, dto).then(function() {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                _this.$back();
              });
            }
          }
        ],
        get(opt) {
          let dto = {
            id: opt.id
          };
          _this
            .post("landlord-service/roomType/queryRoomType", dto)
            .then(data => {
              if (data.roomItemsList && data.roomItemsList.length) {
                data.roomItemsList.forEach(item => {
                  item.itemQuantity = item.itemQuantity.toString();
                  delete item.roomDetailId;
                  delete item.id;
                });
              } else {
                data.roomItemsList = [];
              }
              data.apartmentId = data.apartmentId;
              if(data.facilities){
               data.facilities = data.facilities.split(",");
              }
              data.imgs = [];
              for (var key in data) {
                if (/pic\w/.test(key) && data[key]) {
                  data.imgs.push({
                    name: `${key}.jpg`,
                    url: data[key]
                  });
                }
              }
              //  data.canEdit=0;
            if(data.canEdit==0 && _this.$route.params.type=='edit'){
                _this.opt.form.forEach(item=>{
                    if(item.key=='area'){
                        item.disabled=true;
                    }
                    if(item.key=='roomItemsList'){
                      item.opt.isDisabled=true
                    }
                })
            }
            else{
              
            }
              opt.cb(data);
            });
        },
      },
      config: {
        typeIdShow: true,
        apartmentIdShow: true,
        areaShow: false,
        liftShow: false,
        facilitiesShow: false,
        roomItemsListShow: false,
        itemDescriptionShow: false,
        imgsShow: false,
        descriptionShow: false,

        typeIdRequired: true,
        apartmentIdRequired: true,
        areaRequired: true,
        liftRequired: true,
        facilitiesRequired: false,
        roomItemsListRequired: false,
        itemDescriptionRequired: false,
        imgsRequired: true,
        descriptionRequired: false,
      },
    };
  },
  mounted() {
    this.setConfig()
  },
  created: function () {
    console.log("house-detail created!!");
  },
  activated() {
    this.getApartmentList();
    this.getFacilitiesList();
    this.getTypeIdList();
    if (this.$route.params.id != "0") {
      this.opt.form[0]["disabled"] = true;
      this.opt.form[1]["disabled"] = true;
    } else {
      this.opt.form[0]["disabled"] = false;
      this.opt.form[1]["disabled"] = false;
    }

    this.$refs.detail.title = ["添加房型", "编辑房型", "房型详情"][
      {
        add: 0,
        edit: 1,
        show: 2
      }[this.$route.params.type]
    ];

    this.opt.form.forEach((item) => {
      if (item.key == "area") {
        item.disabled = false;
      }
      if (item.key == "roomItemsList") {
        item.opt.isDisabled = false;
      }
    });
    this.setConfig();
  },
  methods: {
    //获取小区列表
    getApartmentList() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        landlordId:
          this.cache.getLS("userInfo")["roleType"] == "1"
            ? null
            : this.cache.getLS("userInfo")["userId"]
      };
      this.post("landlord-service/apartment/queryPagesApartment", dto).then(
        data => {
          for (let i = 0; i < this.opt.form.length; i++) {
            let item = this.opt.form[i];
            if (item.key == "apartmentId") {
              let list = [];
              for (let j = 0; j < data.length; j++) {
                let it = data[j];
                let label = it.name;
                if (it.landlordName) {
                  label += `（${it.landlordName}）`;
                }
                list.push({
                  label: label,
                  value: it.id
                });
              }
              item.opt.list = list;
              break;
            }
          }
        }
      );
    },
    // 获取房型
    getTypeIdList() {
      this.post("landlord-service/base/getRoomTypeAll").then(res => {
        for (let i = 0; i < this.opt.form.length; i++) {
          const item = this.opt.form[i];
          if (item.key == "typeId") {
            let jsonStr = JSON.stringify(res)
              .replace(/name/g, "label")
              .replace(/id/g, "value");
            item.opt.list = JSON.parse(jsonStr);
            this.typeList = item.opt.list;
            break;
          }
        }
      });
    },

    // 获取房型配套
    getFacilitiesList() {
      this.post("landlord-service/base/getFacilitiesAll").then(res => {
        for (let i = 0; i < this.opt.form.length; i++) {
          const item = this.opt.form[i];
          if (item.key == "facilities") {
            let jsonStr = JSON.stringify(res).replace(/id/g, "value");
            item.opt.list = JSON.parse(jsonStr);
            item.opt.list.forEach(item => {
              item.id = item.value.toString();
            });
            break;
          }
        }
      });
    },
    // 设置学校类型 房型配置
    setSchoolHouseTypeConfig() {
      this.getHouseConfig().then((res) => {
        let houseTypeConfig = [];
        houseTypeConfig = res.houseTypeConfig;
        console.log("houseTypeConfig:", houseTypeConfig);
        houseTypeConfig.map((item) => {
          if (item.property == "type_name") {
            this.config.typeIdShow = item.display;
            this.config.typeIdRequired = item.fill;
          }
          if (item.property == "house_owner") {
            this.config.apartmentIdShow = item.display;
            this.config.apartmentIdRequired = item.fill;
          }
          if (item.property == "area") {
            this.config.areaShow = item.display;
            this.config.areaRequired = item.fill;
          }
          if (item.property == "elevator") {
            this.config.liftShow = item.display;
            this.config.liftRequired = item.fill;
          }
          if (item.property == "facilities") {
            this.config.facilitiesShow = item.display;
            this.config.facilitiesRequired = item.fill;
          }
          if (item.property == "room_items") {
            this.config.roomItemsListShow = item.display;
            this.config.roomItemsListRequired = item.fill;
          }
          if (item.property == "items_desc") {
            this.config.itemDescriptionShow = item.display;
            this.config.itemDescriptionRequired = item.fill;
          }
          if (item.property == "type_pic") {
            this.config.imgsShow = item.display;
            this.config.imgsRequired = item.fill;
          }
          if (item.property == "type_desc") {
            this.config.descriptionShow = item.display;
            this.config.descriptionRequired = item.fill;
          }
        });
        this.opt.form.forEach((item) => {
          if (item.key == "typeId") {
            item.notShow = !this.config.typeIdShow;
            item.rules[0].required = this.config.typeIdRequired; 
          }
          if (item.key == "apartmentId") {
            item.notShow = !this.config.apartmentIdShow;
            item.rules[0].required = this.config.apartmentIdRequired;
          }
          if (item.key == "area") {
            item.notShow = !this.config.areaShow;
            item.rules[0].required = this.config.areaRequired;
          }
          if (item.key == "lift") {
            item.notShow = !this.config.liftShow;
            item.rules[0].required = this.config.liftRequired;
          }
          if (item.key == "facilities") {
            item.notShow = !this.config.facilitiesShow;
          }
          if (item.key == "roomItemsList") {
            item.notShow = !this.config.roomItemsListShow;
          }
          if (item.key == "itemDescription") {
            item.notShow = !this.config.itemDescriptionShow;
          }
          if (item.key == "imgs") {
            item.notShow = !this.config.imgsShow;
            item.rules[0].required = this.config.imgsRequired;
          }
          if (item.key == "description") {
            item.notShow = !this.config.descriptionShow;
          }
        });
      });
    },
    // 设置普通 房型配置
    setOrdinaryHouseTypeConfig() {
      this.opt.form.forEach((item) => {
          if (item.key == "typeId") {
            item.notShow = false;
            item.rules[0].required = true; 
          }
          if (item.key == "apartmentId") {
            item.notShow = false;
            item.rules[0].required = true;
          }
          if (item.key == "area") {
            item.notShow = false;
            item.rules[0].required = true;
          }
          if (item.key == "lift") {
            item.notShow = false;
            item.rules[0].required = true;
          }
          if (item.key == "facilities") {
            item.notShow = false;
          }
          if (item.key == "roomItemsList") {
            item.notShow = false;
          }
          if (item.key == "itemDescription") {
            item.notShow = false;
          }
          if (item.key == "imgs") {
            item.notShow = false;
            item.rules[0].required = true;
          }
          if (item.key == "description") {
            item.notShow = false;
          }
        });
    },
    // 设置配置
    setConfig() {
      if(this.$route.params.type == "add") {
        if(this.getSchoolLandlordFlag()) {
          this.setSchoolHouseTypeConfig();
        }else {
          this.setOrdinaryHouseTypeConfig();
        }
      }else if(this.$route.params.type == "edit" || this.$route.params.type == "show") {
        if(this.$route.query.apartmentType == 7) {
          this.setSchoolHouseTypeConfig();
        }else {
          this.setOrdinaryHouseTypeConfig();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
tr {
  text-align: center;
  td {
    width: 300px;
  }
}
span {
  font-size: 14px;
  color: #303133;
}
el-button {
  width: 60%;
}
.grid-content {
  width: 60%;
  text-align: center;
  border-radius: 4px;
  min-height: 36px;
  line-height: 36px;
}
.bg-purple-dark {
  background: #99a9bf;
}
</style>


